<template>
  <div>
    <v-navigation-drawer
      v-model="friendsDrawer"
      class="overflow-visible elevation-6"
      width="325"
      color="menu"
      right
      fixed
      style="overflow: visible; z-index: 5;"
    >
      <v-row
        class="mx-0 flex-column"
        justify="center"
        :style="!connected ? `height: 100%;` : ``"
      >
        <v-col class="pa-0">
          <v-list
            class="py-0"
            two-line
          >
            <auth />

            <template v-if="connected">
              <v-list-item v-if="!unlocked">
                <div><v-btn @click="doUnlock">SIGN TO UNLOCK</v-btn></div>
              </v-list-item>
              <v-list-item v-else>
                <div><v-btn @click="doStream">{{ !bStream ? 'START' : 'STOP' }} GAME STREAM</v-btn></div>
              </v-list-item>
              <v-divider />
              <div :style="connected ? `height: 400px;` : `height: 400px;` ">
                <v-list-item>
                  <v-list-item-title class="subheading font-weight-light pl-4">
                    <v-checkbox v-model="cbOnlineOnly" label="Online Only" /> Friends Online {{ online }}
                  </v-list-item-title>
                </v-list-item>

                <v-menu
                  v-for="(state, addr) in friendFilter"
                  :key="addr"
                  :offset-x="$vuetify.breakpoint.mdAndUp"
                  :offset-y="$vuetify.breakpoint.smAndDown"
                  :left="$vuetify.breakpoint.mdAndUp"
                  :min-width="$vuetify.breakpoint.smAndDown ? '100%' : undefined"
                  attach
                  :max-width="$vuetify.breakpoint.mdAndUp ? 200 : undefined"
                  style="max-height: 0; max-width: 0;"
                >
                  <template #activator="{ attrs, on }">
                    <v-list-item
                      link
                      v-bind="attrs"
                      class="mx-n3"
                      v-on="on"
                    >

                      <v-list-item-avatar width="70px" height="70px" style="border: 3px solid #fff;">
                        <v-img :src="mainHost + `/avatar?id=` + getAvatar(addr)" />
                      </v-list-item-avatar>

                      <v-list-item-action class="justify-center">
                        <v-avatar
                          :color="['yellow','red','green'][state]"
                          size="12"
                        />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title v-text="getDisplay(addr)" />

                        <v-list-item-subtitle v-if="state==0">
                          Pending
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          {{ state==2 ? 'Online' : 'Offline' }}
                        </v-list-item-subtitle>

                      </v-list-item-content>

                      <v-list-item-content v-if="state==0">
                        <v-btn class="d-flex align-center justify-center py-1 px-4 pink" @click="acceptFriend(addr)">ACCEPT</v-btn><v-btn class="d-flex align-center justify-center py-1 px-4 pink" @click="removeFriend(addr)">DENY</v-btn>
                      </v-list-item-content>
                      <v-list-item-content v-if="state==2">
                        <v-btn class="d-flex align-center justify-center py-1 px-4 pink" @click="inviteFriend(addr)">INVITE</v-btn>
                      </v-list-item-content>

                    </v-list-item>
                  </template>

                  <v-list v-if="state==1" :color="$vuetify.breakpoint.mdAndUp ? 'black' : 'grey darken-4'">
                    <v-list-item @click="whisperFriend(addr)">
                      <v-list-item-title>Whisper</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="removeFriend(addr)">
                      <v-list-item-title>Remove Friend</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list v-else-if="state==2" :color="$vuetify.breakpoint.mdAndUp ? 'black' : 'grey darken-4'">
                    <v-list-item @click="whisperFriend(addr)">
                      <v-list-item-title>Whisper</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="removeFriend(addr)">
                      <v-list-item-title>Remove Friend</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="blockFriend">
                      <v-list-item-title>Block</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-list>
        </v-col>

        <v-col
          v-if="!connected"
          class="d-flex justify-center mt-n12"
        >
          <v-avatar
            class="mt-n12"
            color="grey darken-4"
            size="128"
          >
            <v-icon
              color="grey darken-2"
              size="96"
            >
              mdi-account
            </v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <core-fab />
  </div>
</template>

<script>
  // Utilities
  import {
    mapGetters,
    mapMutations,
    mapState,
    mapActions,
  } from 'vuex'

  export default {
    name: 'CoreFriends',

    components: {
      Auth: () => import('@/components/friends/Auth'),
      CoreFab: () => import('./Fab'),
    },
    data: () => {
      return {
        cbOnlineOnly: true,
        bStream: false,
        friendLabel: '',
      }
    },

    computed: {
      ...mapState('profiles', ['profiles']),
      ...mapGetters('friends', ['online']),
      ...mapState('platform', ['mainHost']),
      ...mapState('wallet', ['connected', 'unlocked', 'web3', 'address']),
      ...mapState('friends', [
        'drawer',
        'friends',
        'friend_list',
        'msgAddr',
      ]),
      friendFilter () {
        var list = {}
        for (var addr of Object.keys(this.friend_list)) {
          var state = this.friend_list[addr]
          if (!this.cbOnlineOnly || state === 2) list[addr] = state
        }
        return list
      },
      friendsDrawer: {
        get () {
          return this.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
    },

    methods: {
      ...mapMutations('wallet', ['setUnlocked']),
      ...mapActions('wallet', ['sendMsg']),
      ...mapMutations('friends', ['setDrawer', 'setMsgDrawer']),
      ...mapActions('friends', ['setMsgAddr']),
      /* addToken () {
        this.web3.currentProvider.sendAsync({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: { address: '0xe5caef4af8780e59df925470b050fb23c43ca68c', symbol: 'FRM', decimals: 6, image: 'https://etherscan.io/token/images/ferrumnetworktoken_32.png' },
          },
        })
      }, */
      sendUpdate () {
        var fnc = async () => {
          var data = (await this.$http.get('http://localhost:1337/info')).data
          console.log('z', this.$http)
          this.$http.post('https://' + this.node.host + '/ingame', JSON.stringify({ data, ingame: true, addr: this.address }))
        }
        fnc()
        this.timeOut = setInterval(fnc, 15000) // every 15 seconds
      },
      async doStream () {
        this.bStream = !this.bStream
        if (this.bStream) {
          await this.sendUpdate()
          console.log('do start..', this.node)
        } else {
          clearTimeout(this.timeOut)
          this.$http.post('https://' + this.node.host + '/ingame', JSON.stringify({ ingame: false, addr: this.address }))
        }
      },
      async doUnlock () {
        var jsign = await this.web3.eth.sign(this.web3.utils.sha3('sign'), this.address)
        this.setUnlocked(jsign)
        console.log('do unlock')
      },
      getAvatar (addr) {
        var p = this.profiles[addr]
        if (p && p.avatar) return p.avatar
      },
      getDisplay (addr) {
        var p = this.profiles[addr]
        if (p && p.display) return p.display
        return addr
      },
      blockFriend () {
        console.log('do block')
        this.sendMsg('testzzz')
        //
      },
      menu (event) {
        //
      },
      removeFriend (addr) {
        this.sendMsg(JSON.stringify({ msg: 'remove_friend', addr }))
        console.log('do rmv')
        //
      },
      whisperFriend (addr) {
        //
        this.setMsgDrawer(true)
        this.setMsgAddr(addr)
      },
      acceptFriend (addr) {
        this.sendMsg(JSON.stringify({ msg: 'add_friend', addr }))
      },
    },
  }
</script>
